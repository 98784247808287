@import '../../../scss/theme-bootstrap';

.gnav-offer-v1,
.link-based-offer-v1 {
  background: #fff;
  border-bottom: 1px solid #e3e4e5;
}

.gnav-offer-v1 .outer-wrap,
.link-based-offer-v1 .outer-wrap {
  overflow: hidden;
  @include swap_direction(padding, 20px 20px 15px 20px);
  position: relative;
}

.gnav-offer-v1 .gnav-offer-close,
.link-based-offer-v1 .link-based-offer-close {
  background: transparent url($img_base_path + '/icon-close.png') 0 0 no-repeat;
  cursor: pointer;
  height: 21px;
  position: absolute;
  #{$rdirection}: 0;
  text-align: #{$rdirection};
  top: 20px;
  width: 22px;
}

.gnav-offer-v1.hide-x .gnav-offer-close,
.link-based-offer-v1.hide-x .link-based-offer-close {
  display: none;
}

.gnav-offer-v1 .gnav-offer-image,
.link-based-offer-v1 .link-based-offer-image {
  float: #{$ldirection};
  height: auto;
  @include swap_direction(margin, 0 50px 0 0);
  max-width: 120px;
}

.gnav-offer-v1 h2,
.link-based-offer-v1 h2 {
  @include swap_direction(margin, 10px 0 0 0);
  line-height: 1;
}

.gnav-offer-v1 .subheading,
.link-based-offer-v1 .subheading {
  color: #000;
  font-size: 26px;
  font-family: $helvetica-light-font-family;
  line-height: 1;
}

.gnav-offer-v1 .subheading p,
.link-based-offer-v1 .subheading p {
  margin: 0;
}

.gnav-offer-v1 .gnav-offer-link,
.link-based-offer-v1 .cta-link {
  bottom: 15px;
  font-size: 13px;
  font-family: $helvetica-regular-font-family;
  position: absolute;
  #{$rdirection}: 0;
  text-align: #{$rdirection};
}

@media screen and (max-width: 1024px) {
  .gnav-offer-v1 h2,
  .link-based-offer-v1 h2 {
    font-size: 26px;
    @include swap_direction(margin, 15px 0 0 0);
  }
  .gnav-offer-v1 .subheading,
  .link-based-offer-v1 .subheading {
    font-size: 18px;
  }
  .gnav-offer-v1 .gnav-offer-close,
  .gnav-offer-v1 .gnav-offer-link,
  .link-based-offer-v1 .link-based-offer-close,
  .link-based-offer-v1 .cta-link {
    #{$rdirection}: 20px;
  }
}

.gnav-countdown-content {
  bottom: 38px;
  color: #b43d52;
  font-size: 19px;
  position: absolute;
  right: 0;
  text-transform: capitalize;
}
